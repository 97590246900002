<template>
  <div>
    <BadReceiptRegEndComponent goods-type="S0106" />
  </div>
</template>
<script>
import BadReceiptRegEndComponent from './BadReceiptRegEndComponent'

export default {
  name: 'EmptView',
  components: {
    BadReceiptRegEndComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {
  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
